body {
  margin: 0;
  font-family: 'Golos Text', sans-serif; /* Ensure to specify a fallback */
}

/* Header */
.header {
  font-size: 3em;
  color: #ffffff; /* Ensure contrast against the background */
  position: absolute; /* Positioning over the image */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust to true center */
  text-align: center; /* Center text */
  z-index: 2; /* Ensure it appears above the image */
}

/* Article section */
.article {
  position: relative;
}

.picture {
  display: block;
  position: relative;
  overflow: hidden;
  height: 76vh;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.75);
}

/* Showroom section */
.businfo {
  background-color: #f7f7f7;
  padding: 2em; /* More padding for a spacious feel */
  margin: 16px auto; /* Center the container */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.title2 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}

.addy {
  font-size: 1.1em;
  text-align: center;
  margin: 0.5em 0;
}

/* Contact buttons */
.contact-buttons {
  display: flex;
  justify-content: center;
  margin: 2em 0;
}

.contact {
  padding: 16px 36px;
  border-radius: 9px;
  margin: 0 10px;
  text-decoration: none;
  background-color: rgb(47, 48, 112);
  color: white;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.contact:hover {
  background-color: rgb(47, 48, 112);
  transform: scale(1.05);
}

/* Hours section */
.hours-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1em;
  gap: 1em; /* Space between sections */
}

.hours-section {
  flex: 1;
  min-width: 250px;
  text-align: center;
  padding: 1em;
  border: 1px solid #ddd; /* Border for distinction */
  border-radius: 8px; /* Rounded corners */
  background-color: #ffffff; /* Background for contrast */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.seasonHead {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.bold-text {
  font-weight: bold;
  font-size: 1.1em;
  color: #333;
  margin-right: 5px;
}

/* Column container */
.column-container {
  display: flex; /* Use flexbox to arrange items */
  flex-wrap: wrap; /* Allow items to wrap into multiple rows */
  justify-content: space-between; /* Space out the items */
  margin: 20px 0; /* Margin for vertical spacing */
}

.grid-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1em;
  gap: 1em; /* Space between sections */
    flex: 1;
    min-width: 20px;
    text-align: center;
    padding: 1em;
    border-radius: 8px; /* Rounded corners */
}

.areas-we-rent,
.areas-we-serve {
  flex: 1;
  min-width: 250px; /* Minimum width for responsiveness */
  text-align: center;
  padding: 1em;
  border: 1px solid #ddd; /* Border for distinction */
  border-radius: 8px; /* Rounded corners */
  background-color: #ffffff; /* Background for contrast */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 1em;
}

.title2 {
  font-size: 24px; /* Title size */
  text-align: center;
  margin-bottom: 15px; /* Space below title */
}

.rent-list,
.areas-list {
  list-style: none;
  padding: 0;
  max-height: 200px; /* Set a max height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  margin: 0; /* Remove margin for consistency */
}

.rent-list li,
.areas-list li {
  padding: 8px 10px; /* Padding for list items */
  margin-bottom: 5px; /* Space between items */
  border-bottom: 1px solid #ddd; /* Divider */
}

.rent-list li:last-child,
.areas-list li:last-child {
  border-bottom: none; /* Remove border from last item */
}

.area-link {
  display: inline-block; /* Change to inline-block for fixed width */
  text-decoration: none;
  color: #333; /* Adjust color */
  font-weight: bold;
  transition: background-color 0.3s ease;
  border-radius: 4px; /* Rounded corners for links */
  padding: 8px; /* Padding for clickable area */
  width: 200px; /* Set a fixed width for the hover box */
}

.area-link:hover {
  background-color: rgb(47, 48, 112); /* Change background on hover */
  color: white; /* Change text color on hover */
}

/* Responsive behavior */
@media (max-width: 768px) {
  .column-container {
      flex-direction: column; /* Stack sections on small screens */
  }

  .areas-we-rent,
  .areas-we-serve {
      width: 100%; /* Full width on small screens */
      min-width: 100%; /* Prevent minimum width issues */
  }

  .header {
      font-size: 2em; /* Adjust header size for smaller screens */
  }

  .businfo {
      padding: 1em; /* Reduce padding for smaller screens */
  }

  .title2 {
      font-size: 20px; /* Adjust title size */
  }

  .contact {
      padding: 12px 24px; /* Adjust button padding */
  }

  .hours-section {
      min-width: 100%; /* Full width for hours section on mobile */
      margin-bottom: 1em; /* Add space between hours sections */
  }

  .map-tour-container {
    display: flex; /* Use flexbox to arrange children */
    justify-content: space-between; /* Space between the two components */
    align-items: flex-start; /* Align items at the start */
    margin: 2em 0; /* Add some margin around the container */
}

/* Google Maps and Tour styles */
.google-map,
.google-tour {
    flex: 1; /* Allow both components to grow equally */
    margin: 0 1em; /* Add some horizontal margin between them */
    min-width: 300px; /* Set a minimum width for better responsiveness */
}


}

@media screen and (max-width: 390px) {
  .header {
      font-size: 1.3em; /* Further adjust header size */
      padding: 8px; /* Slightly less padding */
  }

  .title2 {
      font-size: 16px; /* Smaller titles for very small screens */
  }

  .contact {
      font-size: 0.85em; /* Adjust button text size */
  }

  .businfo {
      padding: 0.3em; /* Minimal padding */
  }

  .rent-list,
  .areas-list {
      max-height: 120px; /* Reduce max height for better fit */
  }
}

@media (max-width: 768px) {
  .map-tour-container {
      flex-direction: column; /* Stack components vertically */
      align-items: center; /* Center the components */
  }

  .google-map,
  .google-tour {
      width: 100%; /* Make them full width on smaller screens */
      margin: 0; /* Remove horizontal margin */
      min-width: 0; /* Remove min-width for stacking */
  }
}