.logo {
  height: 136px;
  width: 336px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  justify-items:center ;
}

.navbar-toggler{
  background-color: rgb(229, 227, 227);
}

.navbar {
  font-family: 'Golos Text',;
  background-color: rgb(210, 210, 210);
}
