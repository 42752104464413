.tablesPage {
    background-color: white;
    margin-top: 24px;
    padding-bottom: 24px;
    font-family: 'Golos Text',;
  }

  .tableTitle {
    text-align: center;
    text-decoration: underline;
    padding-bottom: 1%;
    padding-top: 2%;
    font-family: 'Golos Text',;
  }


  