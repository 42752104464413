.expandable-image {
    width: 150px; /* Default width */
    height: 150px; /* Default height */
    transition: transform 0.3s ease; /* Optional: smooth expansion */
    cursor: pointer;
  }
  
  .expandable-image.expanded {
    transform: scale(2.5); /* Adjust scale for expanded size */
  }
  
  .image-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between images */
    justify-content: center;
  }
  