.photo-gallery {
    text-align: center;
    text-decoration: underline;
    padding-bottom: 1%;
    padding-top: 2%;
    font-family: 'Golos Text',;
}

.photo-gallery img {
    width: 250px;
    height: 250px;
}