p {
    text-align: center;
}

footer {
    background-color: rgb(229, 227, 227);
    color: rgb(0, 0, 0);
    font-size: 14px;
    position: absolute;
    width: 100%;
    font-family: 'Cinzel', serif;
}

.wrapper {
    display: flex;
    list-style: none;
    height: 120px;
    width: 100%;
    padding: 0px;
    font-family: "Poppins", sans-serif;
    justify-content: center;
  }
  
  .wrapper .icon {
    position: relative;
    background: #fff;
    border-radius: 50%;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #fff;
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #fff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  /* .wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
   */
  .wrapper .icon:hover span,
  .wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
  
  .wrapper .facebook:hover,
  .wrapper .facebook:hover .tooltip,
  .wrapper .facebook:hover .tooltip::before {
    background: #f81500;
    color: #fff;
  }
  
  .wrapper .twitter:hover,
  .wrapper .twitter:hover .tooltip,
  .wrapper .twitter:hover .tooltip::before {
    background: #3780ff;
    color: #fff;
  }

  .wrapper .insta:hover,
  .wrapper .insta:hover .tooltip,
  .wrapper .instag:hover .tooltip::before {
    background: #fccc63;
    color: #fff;
  }
  
  .wrapper .instagram:hover,
  .wrapper .instagram:hover .tooltip,
  .wrapper .instagram:hover .tooltip::before {
    background: #015a75;
    color: #fff;
  }
  
  a {
    color: black;
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 20px; */
  }
  
  ul.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  
  li.icon {
    margin: 0 10px;
  }
  
  p {
    text-align: center;
    font-size: 14px;
    /* margin-top: 10px; */
  }

  .socialbut {
    display: flex;
    justify-content: center;
  }