
body {
    font-family: 'Golos Text', sans-serif;
    background-color: #f7f7f7;
    margin: 0;
    padding: 0;
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 36px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  ol {
    font-size: 16px;
    line-height: 1.5;
    margin-left: 20px;
    text-align: center;
    list-style-type: none; 
  }
  
  li {
    margin-bottom: 10px;
    text-align: center;
  }
  